import { addDays, lightFormat } from 'date-fns';
import { HTTPError } from 'ky';
import * as v from 'valibot';

import routes from '@/assets/constants/routes-no';
import { apiClient } from '@/lib/api/apiClient';
import { withSearchParams } from '@/lib/utils';
import { API } from '@/services/Config';

export const defaultApplicationsUrl = withSearchParams(
  routes.AGENT_PORTAL_APPLICATIONS,
  {
    sort: 'created_at',
    desc: 'true',
    f_created_at: JSON.stringify([
      lightFormat(addDays(new Date(), -14), 'yyyy-MM-dd'),
      null,
    ]),
  },
);

export const automadaClient = apiClient.extend({
  prefixUrl: API.url.replace('api', 'automada'),
  timeout: false,
  credentials: 'include',
});

const ErrorSchema = v.union([
  v.object({
    requestId: v.string(),
    status: v.literal('error'),
    error: v.object({
      type: v.string(),
      message: v.string(),
    }),
    logs: v.array(
      v.object({
        severity: v.string(),
        message: v.string(),
        timestamp: v.string(),
      }),
    ),
  }),
  v.pipe(
    v.object({
      requestId: v.string(),
      error: v.string(),
    }),
    v.transform(({ error, requestId }) => ({
      requestId,
      status: 'error' as const,
      error: { type: 'UnknownError', message: error },
      logs: [],
    })),
  ),
]);

export const parseApiError = async (error: unknown) => {
  if (error instanceof HTTPError) {
    const body = await error.response.text();
    const requestId = error.response.headers.get('x-request-id');
    let json;
    try {
      json = JSON.parse(body);
    } catch {
      json = { error: body };
    }
    return v.parse(ErrorSchema, { ...json, requestId });
  }
};

export type AutomadaLog = {
  message: string;
  severity: string;
  timestamp: string;
};

export class AutomadaError extends Error {
  public readonly logs: AutomadaLog[];
  public readonly type: string;
  public readonly requestId: string;

  constructor(public readonly parsed: v.InferOutput<typeof ErrorSchema>) {
    super(parsed.error.message);
    this.name = parsed.error.type;
    this.logs = parsed.logs;
    this.type = parsed.error.type;
    this.requestId = parsed.requestId;
  }
}

export const transformAutomadaErrorIntoError = async (error: unknown) => {
  const parsed = await parseApiError(error);

  if (parsed) {
    throw new AutomadaError(parsed);
  }

  throw error;
};
